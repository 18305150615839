import React, { useState, useCallback } from "react";
import styles from "./ReProvisionSalesforcePage.module.scss";
import { Page } from "../../Page";
import { T } from "../../../components/translation/T";
import { useContractId } from "../../../hooks/useContractId";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../state/contractState";
import { PRODUCT_OFFERINGS } from "../Contract/offering/products/Products";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { dataCompany } from "../../../data/dataCompany";
import { Link } from "../../../components/links/Link";
import { generatePath } from "react-router-dom";
import { REVIEW_ROUTE } from "../Review/ReviewPage";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../components/form/TextInput";
import { dataMerchant } from "../../../data/dataMerchant";
import { AssociateId } from "../../../data/models/CommonTypes";
import { dataAssociates } from "../../../data/dataAssociates";
import { useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { dataBank } from "../../../data/dataBank";
import { dataAdmin } from "../../../data/dataAdmin";
import { ContractStatus } from "../../../data/models/ContractTypes";

export const REPROVISIONING_SALESFORCE_ROUTE = "/review/:contractId/debug";

export const ReProvisionSalesforcePage: React.FunctionComponent = () => {
  const contractId = useContractId();
  const contract = useRecoilValue(contractState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [confirm, setConfirm] = useState<string>("");
  const [statusByPass, setStatusByPass] = useState<Status>(Status.DEFAULT);
  const [statusReminder, setStatusReminder] = useState<Status>(Status.DEFAULT);
  const [signContractStatus, setSignContractStatus] = useState<Status>(
    Status.DEFAULT
  );
  const [bankDeleteStatus, setBankDeleteStatus] = useState<Status>(
    Status.DEFAULT
  );

  const queryClient = useQueryClient();

  const [reminderAssociate, setReminderAssociate] = useState<string>();
  const { t } = useTranslation();

  const [{ data: associates }] = useSuspenseQueries({
    queries: [dataAssociates.fetchAssociates(contractId)],
  });

  const onProvisioning = useCallback(() => {
    setStatus(Status.PENDING);

    dataCompany
      .triggerReprovisioning(contractId)
      .then(() => {
        setStatus(Status.SUCCESS);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      })
      .catch((err) => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId]);

  const onBankReject = useCallback(() => {
    if (confirm.toLocaleLowerCase() !== "yes") {
      console.log(
        'Please type "Yes" in order to confirm the removal of the bank account'
      );
      return;
    }

    dataBank
      .rejectBankAccount(contractId)
      .then(() => {
        setBankDeleteStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataBank.getBankAccountKey(contractId),
        });
      })
      .catch(() => {
        setBankDeleteStatus(Status.ERROR);
        setTimeout(() => {
          setBankDeleteStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId, confirm, queryClient]);

  const onByPass = useCallback(() => {
    setStatusByPass(Status.PENDING);

    dataCompany
      .byPassRisk(contractId)
      .then(() => {
        setStatusByPass(Status.SUCCESS);

        setTimeout(() => {
          setStatusByPass(Status.DEFAULT);
        }, 4000);
      })
      .catch((err) => {
        setStatusByPass(Status.ERROR);

        setTimeout(() => {
          setStatusByPass(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId]);

  const signContract = useCallback(() => {
    dataAdmin
      .postSkipSigning(contractId)
      .then(() => {
        setSignContractStatus(Status.SUCCESS);
      })
      .catch(() => {
        setSignContractStatus(Status.ERROR);

        setTimeout(() => {
          setSignContractStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId]);

  const onRemind = useCallback(() => {
    const associate = associates.find(
      (person) => person.associateId === reminderAssociate
    );

    if (!associate) {
      setStatusReminder(Status.ERROR);

      setTimeout(() => {
        setStatusReminder(Status.DEFAULT);
      }, 4000);

      return;
    }

    dataMerchant
      .resendLink(
        contractId,
        reminderAssociate as AssociateId,
        associate?.contact?.email,
        associate?.contact?.phoneNumber
      )
      .then(() => {
        setStatusReminder(Status.SUCCESS);

        setTimeout(() => {
          setStatusReminder(Status.DEFAULT);
        }, 4000);
      })
      .catch((err) => {
        setStatusReminder(Status.ERROR);

        setTimeout(() => {
          setStatusReminder(Status.DEFAULT);
        }, 4000);
      });
  }, [reminderAssociate, associates, contractId]);

  console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);

  return (
    <div className={styles.reprovisioning}>
      <Page title="Provisioning page">
        <section>
          <article>
            <div className={styles.wrapper}>
              <h2>
                <T>Reprovision contract</T>
              </h2>

              <p>
                <Trans t={t}>
                  Page to test reprovisioning of a contract to Salesforce. The
                  information is bare bones, for more information of the actual
                  data please visit{" "}
                  <Link
                    link={generatePath(REVIEW_ROUTE, {
                      contractId,
                    })}
                  >
                    the review page
                  </Link>
                  .
                </Trans>
              </p>

              <div className={styles.body}>
                <dl>
                  <dt>
                    <T>Company name</T>
                  </dt>
                  <dd>{contract.contractData.companyName}</dd>
                  <dt>
                    <T>Organization identifier</T>
                  </dt>
                  <dd>{contract.contractData.organizationNumber}</dd>
                  <dt>
                    <T>VAT number</T>
                  </dt>
                  <dd>{contract.contractData.taxRegistrationId}</dd>
                  <dt>
                    <T>Product</T>
                  </dt>
                  <dd>{PRODUCT_OFFERINGS[contract.productType].name}</dd>
                </dl>

                <div className="m-top-40">
                  <Button block onClick={onProvisioning} status={status}>
                    Reprovision Salesforce
                  </Button>
                </div>

                {process.env.REACT_APP_ENV === "production" ? null : (
                  <div className="m-top-40">
                    <p>
                      <T>
                        If you are interested in the provisioning of downstream
                        systems you can bypass risk evaluation and trigger
                        provisioning directly
                      </T>
                    </p>

                    <div className="m-top-10">
                      <Button block onClick={onByPass} status={statusByPass}>
                        Bypass risk
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <hr />

              {process.env.REACT_APP_ENV !== "production" &&
              (contract.status === ContractStatus.MERCHANT_SIGNING ||
                contract.status === ContractStatus.MERCHANT_INPUT) ? (
                <div className="m-top-40">
                  <Button
                    block
                    onClick={signContract}
                    status={signContractStatus}
                  >
                    Signera kontrakt
                  </Button>
                </div>
              ) : null}

              <hr />

              <div className="m-top-40">
                <h2>
                  <T>Resend link to primary</T>
                </h2>

                <TextInput
                  label="Associate id"
                  onChange={(value) => setReminderAssociate(value)}
                  value={reminderAssociate}
                />

                <div className="m-top-10">
                  <Button block onClick={onRemind} status={statusReminder}>
                    Remind associate
                  </Button>
                </div>
              </div>

              <hr />

              <div className="m-top-40">
                <TextInput
                  label="Delete bank account"
                  onChange={(value) => setConfirm(value)}
                  value={confirm}
                  hint="type 'Yes' in order to confirm"
                  disabled={bankDeleteStatus !== Status.DEFAULT}
                />
                <div className="m-top-10">
                  <Button
                    block
                    onClick={onBankReject}
                    status={bankDeleteStatus}
                  >
                    Delete bank account
                  </Button>
                </div>
              </div>
            </div>
          </article>
        </section>
      </Page>
    </div>
  );
};
